import logo from "./img/geeksoftheworld.svg";
import "./App.css";
import Header from "./Header.js";

function App() {
    return (
        <>
            <Header />
            <div id="mainLogo">
                <img src={logo} alt="" className="header_logo"></img>
            </div>
        </>
    );
}

export default App;
